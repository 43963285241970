<template>
    <div>
      <va-modal
        v-model="showLog"
        class="flex xs12"
        size="large"
        :hide-default-actions="true"
        :title="'History Logs'"
      >
          <va-data-table
              :fields="changesFields"
              :data="changesFilteredData"
              :per-page="5"
           >
           <template slot="target" slot-scope = "props">
            <span class="text-title">{{ props.rowData.target }}</span>
            </template>
           <template slot="from" slot-scope="props">
              <span v-if="VerifyAndSanitize(props.rowData.from)"  class="text-danger"><VueJsonPretty :data="VerifyAndSanitize(props.rowData.from)"/></span>
              <span v-else class="text-danger">{{ props.rowData.from }} <i class="fa fa-arrow-down"></i></span>
            </template>
            <template slot="to" slot-scope="props">
              <span v-if="VerifyAndSanitize(props.rowData.to)"  class="text-success"><VueJsonPretty :data="VerifyAndSanitize(props.rowData.to)"/></span>
              <span v-else class="text-success">{{ props.rowData.to }} <i class="fa fa-arrow-up"></i></span>
            </template>
          </va-data-table>
          <div class="pt-2">
            <va-button
                slot="actions"
                @click="showLog = false"
                color="danger"
                class="mr-2"
            > Close </va-button>
          </div>
      </va-modal>
      <va-modal
        v-model="showLogData"
        class="flex xs12"
        size="large"
        :hide-default-actions="true"
        :title="'History Logs'"
      >
          <p><b>Object</b></p>
          <p>Area: {{ selectedLogData.area  }}</p>
          <p>Created At: {{ selectedLogData.createdAt  }}</p>
          <p>Updated At: {{ selectedLogData.updatedAt  }}</p>
          <br/>
          <p><b>Admin</b></p>
          <p>Id:  <router-link :to="'/users/edituser/' + selectedLogData.admin.id" >{{ selectedLogData.admin.id }}</router-link></p>
         
          <p>Name:  {{ selectedLogData.admin.name  }}</p>
          <br/>
          <p><b>Event</b></p>
          <p>Type: {{ selectedLogData.event.type  }}</p>
          <p>Target: {{ selectedLogData.event.target  }}</p>
          <hr/>
          <p></p>
           <div class="pt-2">
            <va-button
                slot="actions"
                @click="showLogData = false; selectedLog = selectedLogData.changes; showLog = true; "
                color="primary"
                class="mr-2"
            > View Changes </va-button>
            <va-button
                slot="actions"
                @click="showLogData = false"
                color="danger"
                class="mr-2"
            > Close </va-button>
          </div>
      </va-modal>
      <va-card
        title="History Logs"
      >
        <template slot="actions">
          <div class="row">
            <va-button
              icon="fa fa-refresh"
              @click="getLogs();"
            />
          </div>
        </template>
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search by process, type, status (true/false)...'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"       
          clickable
          hoverable
          :loading="loading"
        >
        <template slot="admin" slot-scope="props">
          <span :title="props.rowData.admin.id"><router-link :to="'/users/edituser/' + props.rowData.admin.id" >{{ props.rowData.admin.name }}</router-link></span>
        </template> 
        <template slot="event" slot-scope="props">
           {{ props.rowData.event.type.charAt(0).toUpperCase() + props.rowData.event.type.slice(1) }}
        </template>
        <template slot="event_target" slot-scope="props">
           {{ props.rowData.event.target }}
        </template>
        <template slot="changes" slot-scope="props">
           <a href="#" @click="selectedLog = props.rowData.changes; showLog = true">Click to Compare</a>
        </template>
        <template slot="createdAt" slot-scope="props">
          {{ props.rowData.createdAt }}
        </template>
        <template slot="actions" slot-scope="props">
            <va-button
                icon="fa fa-eye"
                title="View Log"
                @click="selectedLogData = props.rowData; showLogData = true"
            />
        </template>
        </va-data-table>
      </va-card>
    </div>
  </template>
  <script>
  import firebase from 'firebase';
  import moment from 'moment';
  import {debounce} from 'lodash';
  import VueJsonPretty from 'vue-json-pretty';
  import 'vue-json-pretty/lib/styles.css';
  import LogCreator from "../../class/LogCreator"
  export default {
    components: {
      VueJsonPretty,
    },
    data: function() {
      return {
        loading: true,
        logs: [],
        showLog: false,
        showLogData: false,
        selectedLogData: {
          admin: {
            id: '',
            name: '',
          },
          event: {
            type: '',
            target: '',
         
          },
          changes: [],
        },
        selectedLog: [],
        perPage: '10',
        term: '',
        perPageOptions: ['10', '50', '100', '250'],
      };
    },
    async created() {
      
      await this.getLogs();
    },
    methods: {
      VerifyAndSanitize(data){
        //Check if is an stringified object
        if(typeof data === 'string'){
          try{
            data = JSON.parse(data);
            //itinerate and parse all the object
            for (const [key, value] of Object.entries(data)) {
              if(typeof value === 'string'){
                try{
                  data[key] = JSON.parse(value);
                }catch(e){
                  //Not a stringified object
                }
              }
            }
          }catch(e){
            //Not a stringified object
            return false;
          }

          //itinerate throug 
        }
     

        return data
      },
      async getLogs() {
        this.log = [];
        this.loading = true;

        //get current logged admin
        const admin = await firebase.auth().currentUser;
        LogCreator.setAdmin(admin)
        LogCreator.setEvent('update', '6OyVuWYhCiKcmFIdLPwG')
        LogCreator.addChanges('name', 'old_Name', 'new_Name')
    
        let LogData = LogCreator.create('3D MODELS', firebase.firestore.Timestamp.now() );
        //inset LogData into HistoryLogs collection
        // await firebase.firestore().collection('HistoryLogs').add(LogData);
        //set this.logs as the data from HistoryLogs collection
        await firebase.firestore().collection('HistoryLogs').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              let data = doc.data();
              // Add `createdAtTimestamp` field for sorting.
              data.createdAtTimestamp = data.createdAt.seconds*1000;
              // Convert `createdAt` to a string for display.
              data.createdAt = (moment(new Date(data.createdAt.seconds*1000)).format('MM/D/YYYY, h:mm:ss a')).toString();
              this.logs.push({...data, id: doc.id});
          });
        });
        this.loading = false;
      },
      search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
      }, 100),
    },
    computed: {
      changesFilteredData(){
        if ((!this.term || this.term.length < 1) ) {
            return this.selectedLog
        }
      },
      filteredData() {
        if ((!this.term || this.term.length < 1) ) {
            //order this.logs by createdAt
            this.logs.sort((a, b) => {
                return b.createdAtTimestamp - a.createdAtTimestamp;
            });
            return this.logs;
        }
        return this.logs.filter((item) => {
          let returnLog = false;
          if (item.id.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.area.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.admin.name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.admin.id.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.event.type.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.event.target.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.createdAt.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          return returnLog;
        });
      },
      changesFields(){
        return [
          {
            name: '__slot:target',
            title: 'Field',
          },
          {
            name: '__slot:from',
            title: 'Old Value',
          },
          {
            name: '__slot:to',
            title: 'New Value',
          },
        ]
      },
      fields() {
        return [{
          name: 'id',
          title: 'ID',
        },
        {
            name: 'area',
            title: 'Area',
        },
        {
          name: '__slot:admin',
          title: 'Admin Name',
        },
        {
          name: '__slot:event',
          title: 'Type',
        },
        {
          name: '__slot:event_target',
          title: 'Target',
        },
        {
          name: '__slot:changes',
          title: 'Changes',
        },
        {
          name: '__slot:createdAt',
          title: 'Created At',
          sortField:'createdAt'
        },
        {
          name: '__slot:actions',
          title: 'Actions',
        },
    
    ];
      },
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #3f51b5;
    font-weight: 700;
  }

  .text-danger {
    color: #f44336;
  }

  .text-success {
    color: #4caf50;
  }
  </style>